const InquiryConfig = {
  // お問い合わせ種類
  // resources/lang/ja/items.phpに定義されているものと同じものを定義する
  inquiryTypes: {
    vaboo : [
      { code: '11', name: 'お申込み内容の変更について' },
      { code: '12', name: '集荷日時・箱数の変更・キャンセルについて' },
      { code: '13', name: '査定状況の確認、査定結果について' },
      { code: '14', name: '買取対象商品について' },
      { code: '15', name: '本人確認書類について' },
      { code: '16', name: 'お振込み・振込先口座について' },
      { code: '17', name: '会員登録について' },
      { code: '18', name: 'ご利用方法に関するお問い合わせ' },
      { code: '53', name: 'キャンペーン・クーポンについて' },
      { code: '52', name: 'WEBサイトの不具合他、ご意見・ご要望について' },
      { code: '19', name: '買取に関するその他のお問い合わせ' },
    ],
    charibon : [
      { code: '11', name: 'お申込み内容の変更について' },
      { code: '12', name: '集荷日時・箱数の変更・キャンセルについて' },
      { code: '13', name: '査定状況の確認、査定結果について' },
      { code: '14', name: '買取対象商品について' },
      { code: '18', name: 'ご利用方法に関するお問い合わせ' },
      { code: '53', name: 'キャンペーン・クーポンについて' },
      { code: '52', name: 'WEBサイトの不具合他、ご意見・ご要望について' },
      { code: '56', name: '団体登録についてのお問い合わせ' },
      { code: '58', name: '法人・団体・イベント等での活用について' },
      { code: '57', name: 'ご寄付に関するその他のお問い合わせ' },
    ]
  }
}

export default InquiryConfig;